// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}
.cs-message__html-content img,.msg_img{
  max-width: 250px;
  width: 100%;
  height: auto;
}
.unread_chat_head .cs-conversation__name{
  font-weight: 700;
}
.cs-message-input__content-editor img{
  max-width: 100%;
  display: none;
}
.modal_width_preview{
  min-width: 90%;
}
.modal_width_preview img,.modal_width_preview video{
  max-height: 80vh;
    width: auto;
    max-width: 90%;
}
.ovrlay{
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.3);
}
.cs-message--incoming .cs-message__sent-time,.cs-message--outgoing .cs-message__sent-time {
  display: block !important;
}
.cs-message--incoming .cs-message__sent-time{
  margin-left: 0px !important;
}

.selected_role_btn{
  background-color: #6ea9d7 !important ;
    color: white !important ;
}
// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f6f8fb;
  opacity: 1;
}

.form-control,.form-control:focus {color: black;}

.modal{
  background-color: rgba(0, 0, 0, 0.45);
}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  background: transparent;
  padding: 18px 16px;
}

.capital{
  text-transform: capitalize;
}

.font_19{
  font-size: 19px;
}

.font_16{
  font-size: 16px;
}
.input-group-text{
  border: 0;
    padding: 0 4px;
    background: white;
    color: #3c4b64;
}
#loadermodal{
  z-index:999999999;
}
.card-header .btn:focus, card-header.btn.focus {
  outline: 0;
  box-shadow: none;
}

.home_bg{
  background: #f2f6ff;
}
.hide{
  display: none;
}

.booking_status{
  font-size: 15px;
  color: white;
}
.text-black{
  color: black;
}

// suggestion
.suggestionDiv{
  margin-top: -16px;
    background: white;
    position: absolute;
    max-height: 90px;
    overflow-y: auto;
    width: 92%;
    z-index: 99;
}
.suggestionDivModal{
  min-height: 150px;
}

.pointer{
  cursor: pointer;
}
.border-round{
  border-radius: 50%;
}

.rdw-editor-main {
  background: #f7f7f7;
  padding: 10px;
  min-height: 200px;
  max-height: 540px;
}

.close_btn_m{
  position: absolute;
  right: 24px;
  top: 24px;
}

.report_review_preview{
  border: 1px solid gray;
    border-radius: 16px;
    margin-top: 25px;
}

.form-check-input{
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  margin-left: 0 !important;
  vertical-align: top;
  background-color: #fff;
  position: inherit !important;
  background-repeat: no-repeat;
}